import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoggedInUser } from 'src/app/models/logged-in-user.model';
import { UserLoggedIn, UserLoggedOut } from './user.action';

interface UserStateModel {
  user?: LoggedInUser | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {},
})
@Injectable()
export class UserState {
  @Selector()
  public static user(state: UserStateModel): LoggedInUser | undefined | null {
    return state.user;
  }

  @Selector([UserState.user])
  public static token(user?: LoggedInUser | null): string | undefined {
    return user?.token;
  }
  @Selector([UserState.user])
  public static isScopped(user?: LoggedInUser | null): boolean {
    return !!user?.categoryId;
  }
  @Selector([UserState.user])
  public static isUnscopped(user?: LoggedInUser | null): boolean {
    return !user?.categoryId;
  }

  @Action(UserLoggedIn)
  onUserLoggedIn(ctx: StateContext<UserStateModel>, action: UserLoggedIn) {
    return ctx.patchState({ user: action.user });
  }

  @Action(UserLoggedOut)
  onUserLoggedOut(ctx: StateContext<UserStateModel>) {
    return ctx.patchState({ user: null });
  }
}
